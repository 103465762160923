import UtilityHelper from '../utility-helper/utility-helper';
export default class MdTextAreaElement extends HTMLElement {
  constructor() {
    super();
    this._css = '';
    this._label = null;
    this._placeholder = '';
    this._id = null;
    this._name = null;
    this._defaultValue = '';
    this._required = false;
    this._disableKeyPressEvent = false;
    this._readOnly = false;
    this._disabled = false;
    this._onKeyPress = this._onKeyPress.bind(this);
    this._onFocus = this._onFocus.bind(this);
    this._onBlur = this._onBlur.bind(this);
    this._onChange = this._onChange.bind(this);
  }

  static get observedAttributes() {
    return ['value', 'placeholder', 'readonly', 'disabled'];
  }

  connectedCallback() {
    this.fire = UtilityHelper.fireEvent;

    this._setProperties();

    var dom = this._getDomString(this._label, this._css, this._placeholder, this._id, this._name, this._defaultValue, this._required);

    this._attachToDom(dom);

    this._setEvents();
  }

  attributeChangedCallback(name, oldValue, newValue) {
    if (this.textarea && oldValue) {
      this.textarea.setAttribute(name, newValue);

      if ((name === 'readonly' || name === 'disabled') && (newValue === 'false' || newValue === false)) {
        this.textarea.removeAttribute(name);
      }
    }
  }

  _setProperties() {
    var defaultId = "input-" + UtilityHelper.id(4);
    var css = this.getAttribute('input-class');
    var label = this.getAttribute('label');
    var placeholder = this.getAttribute('placeholder');
    var id = this.dataset.id;
    var name = this.dataset.name;
    var value = this.getAttribute('value');
    var disableKeyPressEvent = this.getAttribute('disable-keypress-event');
    var required = this.getAttribute('required');
    if (css) this._css = css;
    if (label) this._label = label;
    if (placeholder) this._placeholder = placeholder;
    if (id) this._id = id;else this._id = defaultId;
    if (name) this._name = name;else this._name = defaultId;
    if (value) this._defaultValue = value;
    if (disableKeyPressEvent) this._disableKeyPressEvent = true;
    if (required) this._required = true;
    var readOnly = this.getAttribute('readonly');
    if (readOnly) this._readOnly = true;
    var disabled = this.getAttribute('disabled');
    if (disabled) this._disabled = true;
  }

  _setEvents() {
    var onKeyPress = this._onKeyPress;
    var onFocus = this._onFocus;
    var onBlur = this._onBlur;
    var onChange = this._onChange;
    var input = this.textarea;

    if (!this.disableKeyPressEvent) {
      input.addEventListener('keypress', onKeyPress);
    }

    input.addEventListener('focus', onFocus);
    input.addEventListener('blur', onBlur);
    input.addEventListener('change', onChange);
  }

  _removeEvents() {
    var onKeyPress = this._onKeyPress;
    var onFocus = this._onFocus;
    var onBlur = this._onBlur;
    var onChange = this._onChange;
    var input = this.textarea;

    if (!this.disableKeyPressEvent) {
      input.addEventListener('keypress', onKeyPress);
    }

    input.addEventListener('focus', onFocus);
    input.addEventListener('blur', onBlur);
    input.addEventListener('change', onChange);
  }

  _getDomString(label, css, placeholder, id, name, value, required) {
    var dom = '';
    var required = '';
    var readonly = '';
    var disabled = '';
    if (this._required) required = 'required';
    if (this._readOnly) readonly = ' readonly ';
    if (this._disabled) disabled = ' disabled';
    if (!placeholder) placeholder = ' ';

    if (label) {
      dom = '<div class="input-group ' + css + '"><textarea ' + required + readonly + disabled + ' placeholder="' + placeholder + '" id="' + id + '" name="' + name + '">' + value + '</textarea><span class="highlight"></span><span class="bar"></span><label>' + label + '</label></div>'; //dom=`<div class="input-group"`
    } else {
      dom = '<div class="input-group ' + css + '"><textarea ' + required + readonly + disabled + ' placeholder="' + placeholder + '" id="' + id + '" name="' + name + '">' + value + '</textarea><span class="highlight"></span><span class="bar"></span></div>';
    }

    return dom;
  }

  _attachToDom(dom) {
    var $dom = $(dom);
    $(this).append($dom);
    this.textarea = this.querySelector('textarea');
  }

  _onKeyPress(event) {
    if (event.charCode == 13 || event.charCode == 0) {
      var target = event.target;
      var value = target.value;
      event.preventDefault();
      this.fire('md-textarea-enter', {
        value: value,
        target: this
      });
    }
  }

  _onFocus(event) {
    this.fire('md-textarea-focus', {
      target: this
    });
  }

  _onBlur(event) {
    this.fire('md-textarea-blur', {
      target: this
    });
  }

  _onChange(event) {
    this.fire('md-textarea-change', {
      value: event.target.value,
      target: this
    });
  }

  get value() {
    return this.textarea.value;
  }

  set value(val) {
    this.textarea.value = val;
    this.setAttribute('value', val);
  }

  get readonly() {
    return this.input.getAttribute('readonly');
  }

  set readonly(val) {
    if (val) {
      this.input.setAttribute('readonly', 'true');
    } else {
      this.input.removeAttribute('readonly');
    }
  }

  get disabled() {
    return this.input.getAttribute('disabled');
  }

  set disabled(val) {
    if (val) {
      this.input.setAttribute('disabled', 'true');
    } else {
      this.input.removeAttribute('disabled');
    }
  }

  clear() {
    this.textarea.value = '';
  }

}
customElements.define('md-textarea', MdTextAreaElement);